/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Sidebar Social Buttons
        $(".post-social-button-sidebar").click(function(){
          var id = '#' + $(this).parent().closest('div').attr('id');
          $(id + " .post-social-icons").slideToggle('fast', function(){
            $(id).toggleClass('post__social--active');
          });
        });

        // Open/Close the overlay screen
        $(function() {
          $('#toggle-nav').click(function() {
            return $('body').toggleClass('show-nav'), !1;
          }), $(".close-overlay").click(function() {
            return $('body').toggleClass('show-nav'), !1;
          });
        }), $(document).keyup(function(o) {
          27 === o.keyCode && $('body').removeClass('show-nav');
        });

        // Search Dropdown
        $("#search-button").click(function(){
          $("#search-container").slideToggle();
        });

        $("#close-search").click(function(){
          $("#search-container").slideToggle();
        });

        // Back to top
        $('#back-to-top').click(function(e){
          e.preventDefault();

          $('html, body').animate({scrollTop : 0},800);
          return false;
        });

        // Close mobile overlay
        $("#close-overlay").click(function(){
          $('body').removeClass('show-nav');
        });

      }
    },

    'home': {
      init: function() {

        // Hero Swiper
        var heroSwiper = new Swiper('.Hero__slider', {
          direction: 'horizontal',
          autoplay: 6000,
          loop: false,
          slidesPerView: 1,
          spaceBetween: 0,
          nextButton: '.slider-hero-next',
          prevButton: '.slider-hero-previous',
          pagination: '.swiper-pagination',
          paginationType: 'fraction'
        });

        // Homepage  Products Swiper Slider
        var homeSwiper = new Swiper ('.swiper-container--products', {
          direction: 'horizontal',
          loop: true,
          spaceBetween: 30,
          slidesPerView: 3,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          breakpoints: {
            // when window width is <= 500px
            500: {
              slidesPerView: 1,
              spaceBetweenSlides: 10
            }
          }
        });

        // Homepage hero products
        let resourceSlider = new Swiper('.js--swiper-products', {
          direction: 'horizontal',
          loop: false,
          spaceBetween: 50,
          slidesPerView: 4,
          nextButton: '.hero-products__next',
          prevButton: '.hero-products__prev',
          initialSlide: 1,
          centeredSlides: false,
          breakpoints: {
            // when window width is <= 500px
            500: {
              slidesPerView: 1,
              spaceBetween: 30,
              loop: false
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
              loop: false
            },
            991: {
              slidesPerView: 3,
              loop: false
            },
            1460: {
              slidesPerView: 4,
              spaceBetween: 30,
              loop: false
            }
          }
        });

      },
      finalize: function() {
        $.fn.almComplete = function(alm){
          // Social button on posts
          $(".post-social-button").click(function(){
            var id = '#' + $(this).parent().closest('div').attr('id');
            $(id + " .post-social-icons").slideToggle('fast', function(){
              $(id).toggleClass('post__social--active');
            });
          });
        };
      }
    },

    'category': {
      init: function() {

        let masonryInit = true; // set Masonry init flag

        window.almComplete = function(alm) {
          console.log('loaded');

          if($('#masonry-grid').length) {
            let $container = $('#masonry-grid div.alm-listing'); // our container
            if(masonryInit){
              console.log('initiated');
              // initialize Masonry only once
              masonryInit = false;

              $container.masonry({
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
              });
            }else{
              $container.masonry('reloadItems'); // Reload masonry items after callback
            }
            $container.imagesLoaded( function() { // When images are loaded, fire masonry again.
              $container.masonry();
            });
          }
        };
      }
    },

    'search': {
      init: function() {

        // Masonry
        var masonryInit = true;	// set Masonry init flag
        $.fn.almComplete = function(alm){ // Ajax Load More callback function
          if($('#masonry-grid').length){
            var $container = $('#masonry-grid div.alm-listing'); // our container
            if(masonryInit){
              // initialize Masonry only once
              masonryInit = false;
              $container.masonry({
                itemSelector: '.item'
              });
            }else{
              $container.masonry('reloadItems'); // Reload masonry items after callback
            }
            $container.imagesLoaded( function() { // When images are loaded, fire masonry again.
              $container.masonry();
            });
          }
        };

        // Search Button Open
        $("#404-search-btn").click(function(){
          $("#search-container").slideToggle();
        });
      }
    },

    'author': {
      init: function(){
        // Isotope
        var masonryInit = true;	// set Masonry init flag
        $.fn.almComplete = function(alm){ // Ajax Load More callback function
          if($('#masonry-grid').length){
            var $container = $('#masonry-grid div.alm-listing'); // our container
            if(masonryInit){
              // initialize Masonry only once
              masonryInit = false;
              $container.masonry({
                itemSelector: '.item'
              });
            }else{
              $container.masonry('reloadItems'); // Reload masonry items after callback
            }
            $container.imagesLoaded( function() { // When images are loaded, fire masonry again.
              $container.masonry();
            });
          }
        };
      }
    },

    'single': {
      init: function() {

        // Sticky Social
        $(".scroll-fixed").stick_in_parent({
          parent: '.post__content',
          offset_top: 100
        });

        // Post Social (Mobile)
        //$(".post-social-button").click(function(){
        //  var id = '#' + $(this).parent().closest('div').attr('id');
        //  $(id + " .post-social-icons").slideToggle('fast', function(){
        //    $(id).toggleClass('post__social--active');
        //  });
        //});

        // Video posts slider
        var videoSwiper = new Swiper ('.swiper-container', {
          direction: 'vertical',
          loop: false,
          slidesPerView: 3,
          spaceBetween: 10,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev'
        });

        // Related posts slider
        var relatedPostsSwiper = new Swiper('.swiper-related-container', {
          direction: 'horizontal',
          loop: false,
          slidesPerView: 3,
          spaceBetween: 30,
          breakpoints: {
            // when window width is <= 500px
            500: {
              slidesPerView: 1,
              spaceBetweenSlides: 10
            }
          }
        });

      }
    },

    'shop': {
      init: function() {
        var productSwiper = new Swiper('.swiper-container', {
          direction: 'horizontal',
          loop: false,
          spaceBetween: 30,
          slidesPerView: 3,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          breakpoints: {
            500: {
              slidesPerView: 1,
              spaceBetweenSlides: 30
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
